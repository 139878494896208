.Footer-container{
    position: relative;
    
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    flex-direction: column;
    display: flex;
    padding: 1rem 2rem;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f{
    gap: 0.5rem;
    display: flex;
    font-family: "Inter", sans-serif;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo-f>div{
        align-items: center;
        justify-content: center;
        display: flex;
}

.logo-f>div>img{
    width: 3rem;
    height: 3rem;
}

@media screen and (max-width: 768px){
    .Footer-container{
        margin-top: -60px;
    }

    .footer{
        
        margin-bottom: -30px;
       
    }

    .logo-f{
        font-size: x-small;
        align-items: center;
        transform: scale(1.1);
    }

    .logo-f>div{
        gap: 0.5rem;
    }
    .logo-n{
        font-weight: 500;
        font-size: small;
        transform: scale(1.1);
    }


    .Copyright{
        font-size: x-small;
        align-items: center;
        transform: scale(0.8);
        /* width: 100%;   */
    }
 }